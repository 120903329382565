import React from 'react'
import Image from 'gatsby-image';

import Content from 'components/Content';
import "styles/Hero.scss";

const Hero = ({ fluidImage, title, subtitle, description, className = '', column = true, useH1 = false }) => {
  return (
    <section className={`hero heroComponent ${className} ${!column ? "no-column" : ""}`}>
      <Image
        loading="eager"
        fadeIn={false}
        fluid={fluidImage}
        className="hero-image"
      />
      {column ? (
        <div className="container hero-column d-flex justify-content-center justify-content-sm-end">
          <div className="hero-row row justify-content-start justify-content-sm-end align-items-end">
            <div className="col-12 col-md-10 col-lg-8 col-xl-6 card d-block">
              {useH1 ? (
                <h1 className="with-dot-nested mb-4">
                  <Content content={title} />
                </h1>
              ) : (
                <h2 className="with-dot-nested mb-4">
                  <Content content={title} />
                </h2>
              )}
              {subtitle && <Content className="mb-3" content={subtitle} />}
              {description && <Description description={description} />}
            </div>
          </div>
        </div>
      ) : (
        useH1 && (
          <div className="container only-h1 d-flex align-items-center position-absolute h-100">
            <div className="col-12 d-flex justify-content-center justify-content-lg-end">
                <h1 className="with-dot-nested mb-4">
                  <Content content={title} />
                </h1>
            </div>
          </div>
        )
      )}
    </section>
  )
}

const Description = ({ description}) => {
  return typeof description === 'object' ? (
    <div className="mb-4">
      {description}
    </div>
  ) : (
    <Content className="mb-4" content={description} />
  );
}

export default Hero;
