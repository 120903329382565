import React from 'react'

import { useStaticQuery, Link, graphql } from 'gatsby'
import { getLanguage } from "services/language";

import GridCard from './GridCard';

const Vacatures = ({ otherProjects, title, withButton = true }) => {
  const language = getLanguage();

  const { projectsNL, projectsEN } = useStaticQuery(graphql`
    {
      projectsNL: wordpressWpComponenten(slug: {eq: "andere-vacatures"}, wpml_current_locale: {eq: "nl_NL"}) {
        ...projectContent
      }
      projectsEN: wordpressWpComponenten(slug: {eq: "andere-vacatures"}, wpml_current_locale: {eq: "en_US"}) {
        ...projectContent
      }
    }

    fragment projectContent on wordpress__wp_componenten {
      acf {
        title
        cta
        cta_overview {
          text
          relations {
            path
          }
        }
      }
    }
  `)

  const { acf: projectContent } = language === "nl_NL" ? projectsNL : projectsEN;

  return (
    <section className="other-projects">
      <div className="container">
        <h2 className="with-dot mb-4">{title || projectContent.title}</h2>
        <div className="row">
          {otherProjects.map(({ node }, key) => (
            <GridCard
              key={key}
              path={node.path}
              cta={projectContent.cta}
              image={node.acf.banner}
              title={node.title}
            />
          ))}
          {withButton && (
            <div className="col-md-12 text-center">
              <Link className="button" to={projectContent.cta_overview.relations.path}>
                {projectContent.cta_overview.text}
              </Link>
            </div>
          )}
        </div>
      </div>
    </section>
  )
}

export default Vacatures;
